.react-player-coustom-style>video {
  object-fit: cover !important;
}

.powered-by-custom-css {
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(82, 82, 82, 0.9) 100%);
}

.custom-option {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  padding: 10px;
  color: white;
  position: relative;
  background-color: rgba(57, 62, 70, 0.4);
  border-radius: 5px;
  text-align: left;
}

.custom-option::before,
.custom-option::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  content: "";
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.custom-option::after {
  border-color: #00bcee;
  opacity: 0;
  -webkit-transform: translateY(-7px) translateX(6px);
  -moz-transform: translateY(-7px) translateX(6px);
  transform: translateY(-7px) translateX(6px);
}

.custom-option:hover::before,
.custom-option:focus::before {
  opacity: 0;
  -webkit-transform: translateY(5px) translateX(-5px);
  -moz-transform: translateY(5px) translateX(-5px);
  transform: translateY(5px) translateX(-5px);
}

.custom-option:hover::after,
.custom-option:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px) translateX(0px);
  -moz-transform: translateY(0px) translateX(0px);
  transform: translateY(0px) translateX(0px);
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  /* border: 2px solid white; */
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.custom-option-text {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  color: white;
  position: relative;
}

.Toastify__toast {
  border-radius: 5px !important;
}

.header-shadow {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(82, 82, 82, 0.9) 100%
  )
}